import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { Link } from "gatsby"

import Layout from "../components/custom-components/Layout"

import "../css/custom-css/custom-contact-us.css"

import bg from "../images/contact-us/header-contact-us.webp"
import Seo from "../components/seo"

const ContactUs = () => {
  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : ""
  return (
    <Layout page="contactUsPage">
      <Seo title={intl.formatMessage({ id: "header.contactus" })} />

      {/* Header */}
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr header-contact overlay-black-middle bg-pt"
          style={{ backgroundImage: "url(" + bg + ")" }}
        >
          <div className="hero-content">
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white entry-contact">
                  {intl.formatMessage({ id: "header.contactus" })}
                </h1>
                <div className="breadcrumb-row entry-contact">
                  <ul className="list-inline entry-contact">
                    <li>
                      <Link to={`${locale}/`}>
                        {intl.formatMessage({ id: "header.home" })}
                      </Link>
                    </li>
                    <li>{intl.formatMessage({ id: "header.contactus" })}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Us */}
      <div className="section-full content-inner bg-white contact-style-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-flex m-b30">
              <div className="p-a30 border contact-area border-1 align-self-stretch radius-sm">
                <h3 className="m-b5">
                  {intl.formatMessage({ id: "contactus.pvcontact.title" })}
                </h3>
                <p>
                  {intl.formatMessage({ id: "contactus.pvcontact.desc1" })}
                  < br />
                  <b>{intl.formatMessage({ id: "contactus.pvcontact.desc2" })}</b>

                </p>
                <ul className="no-margin">
                  <li className="icon-bx-wraper left  m-b30">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="#" className="icon-cell">
                        <i className="ti-email"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">
                        {intl.formatMessage({
                          id: "contactus.quickcontact.email",
                        })}
                      </h6>
                      <p>pv.kgm@kalbio.co.id</p>
                    </div>
                  </li>
                  <li className="icon-bx-wraper left">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="#" className="icon-cell">
                        <i className="ti-mobile"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">
                        {intl.formatMessage({
                          id: "contactus.quickcontact.phone",
                        })}
                      </h6>
                      <p>0811-8120-1000</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 d-flex m-b30">
              <div className="p-a30 border contact-area border-1 align-self-stretch radius-sm">
                <h3 className="m-b5">
                  {intl.formatMessage({ id: "contactus.quickcontact.title" })}
                </h3>
                <p>
                  {intl.formatMessage({ id: "contactus.quickcontact.desc" })}
                </p>
                <ul className="no-margin">
                  <li className="icon-bx-wraper left m-b30">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="#" className="icon-cell">
                        <i className="ti-location-pin"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">
                        {intl.formatMessage({
                          id: "contactus.quickcontact.address",
                        })}
                      </h6>
                      <p>
                        Kawasan Industri Delta Silicon 3, Jl. Soka Blok F19 No.
                        002, Kel. Cicau, Kec. Cikarang Pusat, Kab. Bekasi, Jawa
                        Barat 17530, Indonesia
                      </p>
                    </div>
                  </li>
                  <li className="icon-bx-wraper left  m-b30">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="#" className="icon-cell">
                        <i className="ti-email"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">
                        {intl.formatMessage({
                          id: "contactus.quickcontact.email",
                        })}
                      </h6>
                      <p>info.kgm@kalbio.co.id</p>
                    </div>
                  </li>
                  <li className="icon-bx-wraper left">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="#" className="icon-cell">
                        <i className="ti-mobile"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">
                        {intl.formatMessage({
                          id: "contactus.quickcontact.phone",
                        })}
                      </h6>
                      <p>021-5094-3200</p>
                    </div>
                  </li>
                </ul>
                <div className="m-t20">
                  <ul className="dlab-social-icon border dlab-social-icon-lg">
                    <li>
                      <div className="icon-bx-xs border-1">
                        <a
                          target="_blank"
                          href="https://www.youtube.com/channel/UCvD1k5epzEmVR40J_KYCinQ"
                          className="icon-cell bg-primary"
                        >
                          <i className="fa fa-youtube-play"></i>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="icon-bx-xs border-1">
                        <a
                          target="_blank"
                          href="https://id.linkedin.com/company/kalbio-global-medika"
                          className="icon-cell bg-primary"
                        >
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-8 d-flex m-b30">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83483.7400731468!2d107.15525619987045!3d-6.297896567368008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e699bcdac6d0a5b%3A0x43610a22d24cf937!2sPT.%20Kalbio%20Global%20Medika!5e0!3m2!1sen!2sid!4v1629530251213!5m2!1sen!2sid"
                style={{ border: "0", width: "100%", minHeight: "100%" }}
                allowfullscreen
              ></iframe>
            </div>

            <div className="col-lg-12 mb-4 m-b30 mb-md-0">
              <div className="p-a30 bg-gray clearfix radius-sm">
                <h3 className="m-b10">
                  {intl.formatMessage({
                    id: "contactus.form.title",
                  })}
                </h3>
                <div className="dzFormMsg"></div>
                <form
                  className="dzForm"
                  acceptCharset="utf-8"
                  // action="https://getform.io/f/afc51cde-6aab-4b5a-a572-73a994e1aeba"
                  action="https://formspree.io/f/xnqwpzre"
                  method="POST"
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="name"
                            type="text"
                            required
                            className="form-control"
                            placeholder={intl.formatMessage({
                              id: "contactus.form.name",
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="email"
                            type="email"
                            className="form-control"
                            required
                            placeholder={intl.formatMessage({
                              id: "contactus.form.email",
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <textarea
                            name="message"
                            rows="4"
                            className="form-control"
                            required
                            placeholder={intl.formatMessage({
                              id: "contactus.form.message",
                            })}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button type="submit" className="site-button btnhover11">
                        <span>
                          {intl.formatMessage({
                            id: "contactus.form.submit",
                          })}
                        </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactUs
